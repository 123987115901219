/* You can add global styles to this file, and also import other style files */
@font-face {
    font-family: 'Open Sans';
    src: url('assets/fonts/opensans/OpenSans-Bold.woff2') format('woff2'), url('assets/fonts/opensans/OpenSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('assets/fonts/opensans/OpenSans-Light.woff2') format('woff2'), url('assets/fonts/opensans/OpenSans-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Open Sans';
    src: url('assets/fonts/opensans/OpenSans-SemiBold.woff2') format('woff2'), url('assets/fonts/opensans/OpenSans-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Open Sans';
    src: url('assets/fonts/opensans/OpenSans-ExtraBoldItalic.woff2') format('woff2'), url('assets/fonts/opensans/OpenSans-ExtraBoldItalic.woff') format('woff');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Open Sans';
    src: url('assets/fonts/opensans/OpenSans-BoldItalic.woff2') format('woff2'), url('assets/fonts/opensans/OpenSans-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Open Sans';
    src: url('assets/fonts/opensans/OpenSans-Italic.woff2') format('woff2'), url('assets/fonts/opensans/OpenSans-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Open Sans';
    src: url('assets/fonts/opensans/OpenSans-ExtraBold.woff2') format('woff2'), url('assets/fonts/opensans/OpenSans-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Open Sans';
    src: url('assets/fonts/opensans/OpenSans-SemiBoldItalic.woff2') format('woff2'), url('assets/fonts/opensans/OpenSans-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Open Sans';
    src: url('assets/fonts/opensans/OpenSans-Regular.woff2') format('woff2'), url('assets/fonts/opensans/OpenSans-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Open Sans';
    src: url('assets/fonts/opensans/OpenSans-LightItalic.woff2') format('woff2'), url('assets/fonts/opensans/OpenSans-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}



html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: "Helvetica Neue", sans-serif;
}

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: 'Open Sans', "Helvetica Neue", sans-serif;
}

h1,
h2,
h3,
h4,
h5 {
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    color: #707478;
}

p {
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.label-tittle {
    color: #17487b !important;
}

.number {
    float: left;
    padding: 0;
    height: 20px;
    width: 20px;
    font-size: 11px;
    line-height: 20px;
    text-align: center;
    margin-right: 10px;
    top: 0;
    background: rgba(0, 0, 0, 0.2);
    color: #fff;
    border-radius: 40px;
    position: relative;
}

.numberNotSelected {
    float: left;
    padding: 0;
    height: 20px;
    width: 20px;
    font-size: 11px;
    line-height: 20px;
    text-align: center;
    margin-right: 10px;
    top: 0;
    background: #358de4 !important;
    color: #fff;
    border-radius: 40px;
    position: relative;
}

.accordion-primary {
    background-color: #343A40;
    color: white;
    font-weight: bold;
    margin: 5px;
}

.accordion-secondary {
    background-color: #666666;
    color: white;
    font-weight: bold;
    margin: 5px;
    padding-top: 7px;
    padding-bottom: 7px;
}

.form-group {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.mat-dialog-container {
    border-radius: 20px !important;
}